import React, { Component } from "react";
import DisableAddOperationsInfo from "../Modals/DisableAddOperationsInfo";
import { useSelector } from "react-redux";

const BasicLayout = ({ children }) => {

    const { disableAddOperations } = useSelector((state) => state.company);
  return (
    <>
      <div className="basic-layout">{children}</div>
      {disableAddOperations && (
        <DisableAddOperationsInfo open={disableAddOperations} />
      )}
    </>
  );
};


export default BasicLayout;
