import React from "react";
import Icon from "../Icon";

export default function IconButton({
  title,
  width,
  height,
  disabled,
  additionStyle = "",
  onButtonClick,
}) {
  return (
    <div className={`relative ${width}`}>
      <button
        type="button"
        disabled={disabled}
        onClick={onButtonClick}
        className={
          disabled
            ? `InActiveIconButton ${width}  ${height} ${additionStyle}
            `
            : `activeIconButton ${width} ${height} ${additionStyle}
           `
        }
      >
        {title}

        {!disabled ? (
          <Icon name="icon-nextActive" additionStyle="top-2.5" />
        ) : (
          <Icon name="icon-nextInActive" />
        )}
      </button>
    </div>
  );
}
