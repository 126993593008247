export const ClarityUserProfileTagHandler = (ltUser, user) => {
  if (window.clarity) {

    if (ltUser && ltUser.email) {
      window.clarity("set", "LTUserEmail", ltUser.email);
      if (user && user.email) {
        window.clarity(
          "identify",
          user.email,
          `${user.id ? `LT-UID-${user.id}` : ""}`,
          "",
          user.name
        );
      }
    } else if (user && user.email) {
      window.clarity("set", "userEmail", user.email);
      window.clarity(
        "identify",
        user.email,
        `${user.id ? `LT-UID-${user.id}` : ""}`,
        "",
        user.name
      );
    }
    return true;
  } else {

    return true;
  }
};
