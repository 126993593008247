import {
  SET_COMPANY_USERS,
  UPDATE_COMPANY_USER,
  DELETE_COMPANY_USER,
  ADD_NEW_COMPANY_USER,
  SAVE_USER_DETAILS,
  SET_COMPANY_LOSS_RUNS_DOCUMENTS,
  SET_COMPANY_DISPATCHERS,
  UPDATE_COMPANY_DISPATCHER,
  SET_COMPANY_DETAILS,
  CLEAR_DATA_WITHOUT_USER,
  CLEAR_DATA,
  SET_NOTES_FLAG,
  UPDATE_NOTES_IN_COMPANY,
  ADD_NEW_COMPANY_DISPATCHER,
  DISABLE_ADD_OPERATIONS,
} from "../Types";

import {
  updateUserHelper,
  removeUserHelper,
  updateCompanyDispatchers,
  setCompanyDispatchers,
  updateCompanyNotes,
  setCompanyMapper,
} from "../Utils/companyUtils";

const INITIAL_STATE = {
  users: [],
  company: null,
  loggedInUser: null,
  lossRuns: [],
  dispatchers: [],
  notesFlag: null,
  disableAddOperations: false,
};

const CompanyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_COMPANY_USERS:
      return { ...state, users: [...action.payload] };
    case ADD_NEW_COMPANY_USER:
      const _users = [...state.users];
      _users.push(action.payload);
      return { ...state, users: [..._users] };
    case UPDATE_COMPANY_USER:
      return {
        ...state,
        users: updateUserHelper([...state.users], action.payload),
      };
    case DELETE_COMPANY_USER:
      return {
        ...state,
        users: [...removeUserHelper([...state.users], action.payload)],
      };
    case SAVE_USER_DETAILS:
      return { ...state, loggedInUser: action.payload };
    case SET_COMPANY_LOSS_RUNS_DOCUMENTS:
      return { ...state, lossRuns: action.payload };
    case SET_COMPANY_DISPATCHERS:
      return { ...state, dispatchers: setCompanyDispatchers(action.payload) };
    case ADD_NEW_COMPANY_DISPATCHER:
      const _dispatchers = [...state.dispatchers];
      _dispatchers.push(action.payload);
      return { ...state, dispatchers: [..._dispatchers] };
    case UPDATE_COMPANY_DISPATCHER:
      return {
        ...state,
        dispatchers: updateCompanyDispatchers(state, action.payload),
      };
    case SET_COMPANY_DETAILS:
      return {
        ...state,
        company: setCompanyMapper(action.payload, state.company),
      };
    case SET_NOTES_FLAG:
      return { ...state, notesFlag: action.payload };
    case UPDATE_NOTES_IN_COMPANY:
      return { ...state, company: updateCompanyNotes(action.payload, state) };
    case CLEAR_DATA:
      return { ...INITIAL_STATE };
    case CLEAR_DATA_WITHOUT_USER:
      return { ...INITIAL_STATE };
    case DISABLE_ADD_OPERATIONS:
      return { ...state, disableAddOperations: action.payload };
    default:
      return state;
  }
};

export default CompanyReducer;
