import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import React, { useEffect, useState } from "react";
import Icon from "../Icon";
import IconButton from "../IconButton";
import { useMediaQuery } from "react-responsive";
import Button from "../Button";
import { useDispatch } from "react-redux";

const DisableAddOperationsInfo = (open) => {
  //   const [open, setOpen] = useState(false);
  console.log("open", open);
  const dispatch = useDispatch();
  const close = () => {
    dispatch({ type: "DISABLE_ADD_OPERATIONS", payload: false });
  };

  const ImageTextRow = ({ imgSrc, phone, website }) => (
    <div className="flex lg:flex-col xs:mt-4 xs:mb-4  xs:flex-col  items-center space-y-2 sm:space-y-0 sm:space-x-4">
      <img
        src={imgSrc}
        alt="Carrier logo"
        className="lg:w-48 xs:w-36 sm:w-28 md:24 object-cover"
      />
      <div className="flex flex-col text-base sm:text-lg font-interMedium text-bgInactiveButton xs:items-center">
        <span className="mt-4">{phone}</span>
        <a
          href={website}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          Visit Website
        </a>
      </div>
    </div>
  );

  return (
    <Popup
      modal
      open={open}
      closeOnDocumentClick={false}
      contentStyle={{
        backgroundColor: "#fff",
        borderRadius: "5px",
        padding: "20px 5px 20px 10px",
        width: "100%",
        position: "absolute",
        bottom: "0",
      }}
    >
      <div className="flex flex-row sm:flex-col xs:flex-col justify-between">
        <div className="w-100">
          <div className="text-xl font-interMedium text-bgInactiveButton mb-8">
            We are currently unable to process service requests. Please contact
            your carrier directly to make any changes to your account or policy.
            Carrier contact information is as follows:
          </div>
          <div className="flex md:flex-row lg:flex-row lg:justify-around md:justify-around xs:flex-col sm:flex-col ">
            <div className="flex justify-between xs:justify-center items-center">
              <ImageTextRow
                imgSrc="/Images/progressiveLogo.webp"
                phone="(888) 240-9839"
                website="https://www.progressive.com/contact-us/"
              />
            </div>
            <div className="flex justify-between xs:justify-center items-center">
              <ImageTextRow
                imgSrc="/Images/Coverwhale.webp"
                phone="(323) 402-5526"
                website="https://www.coverwhale.com/contact/"
              />
            </div>
          </div>
        </div>

        <div className="flex self-center md:mt-4">
          <Button
            title={"OK"}
            height={"h-14"}
            additionStyle={"mx-8"}
            onButtonClick={close}
            className="xs:w-100"
          />
        </div>
      </div>
      {/* </div> */}
    </Popup>
  );
};

export default DisableAddOperationsInfo;
