export const GTMPageView = (url) => {
  if (process.env.NEXT_PUBLIC_NODE_ENV == "production") {
    const pageEvent = {
      event: "pageview",
      page: url,
    };
    window && window.dataLayer && window.dataLayer.push(pageEvent);
    return pageEvent;
  }
};
