import React, { useEffect } from "react";
import Head from "next/head";
import Script from "next/script";
import mixpanel from "mixpanel-browser";
import TagManager from "react-gtm-module";
import { useMutation } from "@apollo/client";
import "react-toastify/dist/ReactToastify.css";
import Router, { useRouter } from "next/router";
import { ToastContainer } from "react-toastify";
import "../lt-dashboard/assets/css/Calendar.css";
import { clarity } from "react-microsoft-clarity";
import "../lt-dashboard/assets/css/DatePicker.css";
import { ErrorBoundary } from "react-error-boundary";
import Button from "../lt-dashboard/components/Button";
import { GTMPageView } from "../lt-dashboard/utils/gtm";
import { withApollo } from "../lt-dashboard/lib/withApollo";
import { PersistGate } from "redux-persist/integration/react";
import { Provider, useDispatch, useSelector } from "react-redux";
import Layout1 from "../lt-dashboard/components/Layouts/Layout1";
import Layout2 from "../lt-dashboard/components/Layouts/Layout2";
import Layout3 from "../lt-dashboard/components/Layouts/Layout3";
import Layout4 from "../lt-dashboard/components/Layouts/Layout4";
import Layout404 from "../lt-dashboard/components/Layouts/Layout404";
import { setUser } from "../lt-dashboard/Redux/Actions/ltUserActions";
import store, { persistor } from "../lt-dashboard/Redux/Reducers/store";
import BasicLayout from "../lt-dashboard/components/Layouts/BasicLayout";
import { ERROR_MESSAGE_TO_SLACK } from "../lt-dashboard/pages/api/mutations";
import { ClarityUserProfileTagHandler } from "../lt-dashboard/utils/clarity";
import { setCompanyDetails } from "../lt-dashboard/Redux/Actions/companyActions";
import {
  CLEAR_DATA,
  CLEAR_DATA_LT,
  SET_LT_SIDEBAR,
  SET_SIDEBAR,
} from "../lt-dashboard/Redux/Types";

// const rollbarConfig = {
//   accessToken:
//     process.env.NEXT_PUBLIC_NODE_ENV !== "production"
//       ? process.env.NEXT_PUBLIC_ROLLBAR_STAGING_TOKEN
//       : process.env.NEXT_PUBLIC_ROLLBAR_PRODUCTION_TOKEN,
//   captureUncaught: true,
//   captureUnhandledRejections: true,
//   environment: "production",
// };

if (process.env.NEXT_PUBLIC_THEME === "LT") {
  require("../lt-dashboard/assets/css/global.css");
} else {
  require("../lt-dashboard/assets/css/flexport-global.css");
}

const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_MEASUREMENT_ID;

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_KEY);
const layouts = {
  L1: Layout1,
  L2: Layout2,
  L3: Layout3,
  L4: Layout4,
  L404: Layout404,
  BasicLayout: BasicLayout,
};

function ErrorFallback({ error, resetErrorBoundary }) {
  const dispatch = useDispatch();

  const restart = () => {
    const LTtoken = localStorage.getItem("LTtoken");
    const mainToken = localStorage.getItem("mainToken");
    if (mainToken && LTtoken) {
      localStorage.removeItem("mainToken");
      dispatch({
        type: CLEAR_DATA,
      });
      dispatch({
        type: SET_SIDEBAR,
      });
      dispatch({
        type: SET_LT_SIDEBAR,
      });

      window.location.href = process.env.NEXT_PUBLIC_LT_TEAM_COMPANIES;
    } else if (LTtoken) {
      localStorage.removeItem("LTtoken");
      window.location.href = process.env.NEXT_PUBLIC_LT_TEAM_LOGIN;
      dispatch({
        type: CLEAR_DATA_LT,
      });

      dispatch(setCompanyDetails(null));
      dispatch(setUser(null));
    } else {
      localStorage.removeItem("mainToken");
      localStorage.removeItem("token");
      localStorage.removeItem("withOutDot");
      localStorage.removeItem("LTtoken");
      dispatch({
        type: CLEAR_DATA,
      });
      dispatch({
        type: SET_SIDEBAR,
      });
      dispatch({
        type: SET_LT_SIDEBAR,
      });
      window.location.href = process.env.NEXT_PUBLIC_USER_LOGIN;
    }
  };

  return (
    <div className=" bg-landing min-h-screen flex-col flex justify-center items-center">
      <div className="flex items-center cursor-pointer">
        {process.env.NEXT_PUBLIC_THEME === "LT" ? (
          <>
            <img
              src="/Images/logo-circle.webp"
              width={"80px"}
              height={"80px"}
              className="rounded-full"
            />
            <img
              src="/Images/wordmark-luckytruck.webp"
              width={"300px"}
              height={"100px"}
              className="rounded-full"
            />
          </>
        ) : (
          <img
            src="/Images/flexport/Logo-Flexport.svg"
            width={"180px"}
            height={"36px"}
          />
        )}
      </div>
      <h1 className=" font-hkBold text-xl text-center">
        Oops! Something went wrong.
      </h1>
      <p className=" font-hkMedium  text-center">
        We apologize for the inconvenience.
      </p>
      <Button onButtonClick={restart} title={"Go Back"} />
      {process.env.NEXT_PUBLIC_NODE_ENV !== "production" ? (
        <pre>{error.message}</pre>
      ) : null}
      😞😞😞😞😞😞
    </div>
  );
}

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  const handleRouteChange = (url) => GTMPageView(url);
  const [errorMessageToSlack] = useMutation(ERROR_MESSAGE_TO_SLACK);

  Router.onRouteChangeComplete = (url) => {
    if (
      typeof window !== "undefined" &&
      process.env.NEXT_PUBLIC_NODE_ENV == "production"
    ) {
      handleRouteChange(url);
    }
  };

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      process.env.NEXT_PUBLIC_NODE_ENV == "production"
    ) {
      TagManager.initialize({ gtmId: "GTM-KKN2MXQN" });
    }

    if (
      typeof window !== "undefined" &&
      process.env.NEXT_PUBLIC_NODE_ENV == "production"
    ) {
      clarity.init(process.env.NEXT_PUBLIC_CLARITY_KEY);
    }

    return () => {
      if (
        typeof window !== "undefined" &&
        process.env.NEXT_PUBLIC_NODE_ENV == "production"
      ) {
        Router.onRouteChangeComplete = (url) => {
          handleRouteChange(url);
        };
      }
    };
  }, []);

  const Layout = layouts[Component.layout] || <>{Component}</>;

  const onError = async (error) => {
    if (process.env.NEXT_PUBLIC_NODE_ENV === "production") {
      const mainToken = localStorage.getItem("mainToken");

      try {
        await errorMessageToSlack({
          variables: {
            normalUser: mainToken ? true : false,
            message: `${error} Error Occurred at path:${router.pathname}`,
            ltUserName: "",
          },
        });
      } catch (error) {
        console.log("Catch Error", error);
      }
    } else {
      console.log("Catch Error", error);
    }
  };

  function RouteChangeHandler() {
    // separate component to access redux and implement clarity user session on every route change
    const { LTuser } = useSelector((state) => state.ltUser);
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
      const handleBeforeUnload = () => {
        ClarityUserProfileTagHandler(LTuser, user);
      };

      const handleRouteChangeComplete = (url) => {
        ClarityUserProfileTagHandler(LTuser, user);
      };

      Router.events.on("beforeHistoryChange", handleBeforeUnload);
      Router.events.on("routeChangeComplete", handleRouteChangeComplete);

      return () => {
        Router.events.off("beforeHistoryChange", handleBeforeUnload);
        Router.events.off("routeChangeComplete", handleRouteChangeComplete);
      };
    }, []);
    return null;
  }

  return (
    // <RollbarProvider config={rollbarConfig}>
    //   <ErrorBoundary fallbackUI={ErrorFallback} callback={onError}>
    <Provider store={store}>
      <Head>
        <title>
          {process.env.NEXT_PUBLIC_THEME === "LT"
            ? "Trucking Insurance Provider | LuckyTruck"
            : "Trucking Insurance Provider | Flexport"}
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <meta
          name="facebook-domain-verification"
          content="rh88eyb15od9lwjoff0jsoalmi80nh"
        />
        {/* gtm */}
        {typeof window !== "undefined" &&
          process.env.NEXT_PUBLIC_NODE_ENV == "production" && (
            <>
              {/* <link rel="preconnect" href="https://www.googletagmanager.com" /> */}
              {/* <script
                type="text/partytown"
                dangerouslySetInnerHTML={{
                  __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-MLP8N46');`,
                }}
              /> */}
              <Script
                src="https://www.googletagmanager.com/gtm.js?id=GTM-KKN2MXQN"
                strategy="lazyOnload"
              />
            </>
          )}
        <script
          defer
          src="https://cdn.jsdelivr.net/npm/pdfjs-dist@2.13.216/build/pdf.min.js"
        />
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "LuckyTruck",
            "url": "https://flexport.luckytruck.com/",
            "logo": "https://flexport.luckytruck.com/Images/wordmark-luckytruck.webp",
            "sameAs": [
              "https://www.facebook.com/LuckyTruckPlatform",
              "https://www.luckytruck.com/Images/linkedin.png",
              "https://www.luckytruck.com/"
            ]
          }
        `}</script>
        <link rel="canonical" href="https://flexport.luckytruck.com/" />
        {/* <link
          rel="preload"
          href={riveWasmUrl}
          as="fetch"
          crossOrigin="anonymous"
        /> */}
        ;{/* scripts end */}
        {/* pwa meta tags */}
        <meta name="application-name" content="LuckyTruck" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        {/* <meta
          name="google-site-verification"
          content="JDDw_AaFgHPX_e5srcC9FC1Wno5xeW40653vHsuLYNw"
        /> */}
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="LuckyTruck" />
        <meta
          name="description"
          content="LuckyTruck | We're here to help! Buy commercial trucking insurance and manage it through one seamless platform. Get started now"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        {/* <meta name="msapplication-config" content="/icons/browserconfig.xml" /> */}
        <meta name="msapplication-TileColor" content="#2B5797" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="theme-color" content="#5468FF" />
        {/* <link rel="apple-touch-icon" href="/icons/touch-icon-iphone.png" /> */}
        {/* <link rel="manifest" href="/manifest.json" /> */}
        {/* <link rel='mask-icon' href='/icons/safari-pinned-tab.svg' color='#5bbad5' /> */}
        {/* Setting favicon for flexport conditionally */}
        {process.env.NEXT_PUBLIC_THEME === "LT" ? (
          <>
            <link
              rel="apple-touch-icon"
              sizes="152x152"
              href="/Images/logo152x152.png"
            />

            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/Images/logo180x180.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="167x167"
              href="/Images/logo167x167.png"
            />

            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/Images/logo32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/Images/logo16x16.png"
            />
            <link rel="shortcut icon" href="/favicon.ico" />
            <link rel="icon" href="/favicon.ico" />
            {/* <link rel="manifest" href="/manifest.json" /> */}
          </>
        ) : (
          <>
            <link rel="shortcut icon" href="/favicon-flexport.ico" />
            <link rel="icon" href="/favicon-flexport.ico" />
            {/* <link rel="manifest" href="/manifest.json" /> */}
          </>
        )}
        {/* <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Roboto:300,400,500' /> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="LuckyTruck" />
        <meta
          property="og:description"
          content="LuckyTruck | We're here to help! Buy commercial trucking insurance and manage it through one seamless platform. Get started now"
        />
        <meta property="og:site_name" content="LuckyTruck" />
        <meta
          property="og:url"
          content={process.env.NEXT_PUBLIC_PRIVACY_POLICY_URL}
        />
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_PRIVACY_POLICY_URL}/apple-touch-icon.png`}
        />
      </Head>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
          {typeof window !== "undefined" &&
            process.env.NEXT_PUBLIC_NODE_ENV == "production" && (
              <RouteChangeHandler />
            )}

          <Layout>
            <ToastContainer
              autoClose={5000}
              bodyClassName={"font-hkBold text-inputColor"}
            />

            <Component {...pageProps} />
          </Layout>
        </ErrorBoundary>
      </PersistGate>
    </Provider>
    //   </ErrorBoundary>
    // </RollbarProvider>
  );
}

export default withApollo()(MyApp);
